import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';
import ProTip from '../components/ProTip';
import Copyright from '../components/Copyright';
import Header from '../components/header';
import Content from '../components/Content';
import PageIntro from '../components/pageIntro';
import PageTitle from '../components/pageTitle';
import Card4 from '../components/Card4';




export default function About() {
  return (
    <Grid Container direction="column">
      <Grid item>
        <Header title="Expertise"/>
      </Grid>
      <Grid item>
      <Container maxWidth="sm">
      <Box my={4}>
      <PageTitle/>
      <PageIntro content="Mes expertises en tant que développeur ou en tant que responsable sont un passage obligé pour être légitime, et ce à tous les niveaux de l'architecture d'un projet." />
      <Card4 title="Ionic" customer="" description="Je connais bien les intérêts et les limites d'ionic pour réaliser une application mobile ou une PWA, ainsi que les éléments de 'layout' et les différents 'components' permettant une intégration la plus efficace possible " moreLink="" />
<Card4 title="illustrator" customer="" description="J'utilise Illustrator essentiellement pour créer des icones qui correspondent exactement aux messages que je souhaite faire passer sur un écran. Mais aussi pour jouer avec ma tablette graphique =)" moreLink="" />
<Card4 title="React" customer="" description="J'utilise React pour développer des applications web basiques, et comparer le fonctionnement d'une appli React à ce qui se fait avec Angular" moreLink="" />
<Card4 title="Netlify" customer="" description="J'ai utilisé Netlify mais aussi Heroku pour profiter de la facilité de déploiement (avec le CI/CD complètement intégré, directement branché sur le repo Github)" moreLink="" />
<Card4 title="MySQL" customer="" description="J'utilise mySQL au quotidien pour accéder et intervenir sur les bases de données via des requêtes SQL , y compris sur des environnements de production. Je l'utilise également pour générer des dumps de base de données" moreLink="" />
<Card4 title="Git" customer="" description="Que ce soit pour structurer le travail d'une équipe ou en tant que développeur, j'utilise git et les différents services d'hébergement de repos (github, bitbucket, gitlab…), en profitant des bonnes pratiques de commit, branches, merges…" moreLink="" />
<Card4 title="Adobe XD" customer="" description="J'utilise Adobe XD (mais également Sketch et Invision) pour maquetter les applications que je souhaite matérialiser, en général pour permettre aux porteurs de projet de se projeter, ou pour les confronter à de futurs utilisateurs lors d'ateliers" moreLink="" />
<Card4 title="Signature électronique" customer="" description="Je connais le fonctionnement et la mise en place des outils de signature électronique, via SDK et API pour une intégration dans un processus métier ; j'ai en particulier utilisé Yousign" moreLink="" />
<Card4 title="Intégration emails" customer="" description="Je maîtrise l'intégration d'emails, des règles d'ergonomie iposées par la variété des clients mails, des OS et des 'devices' aux contraintes techniques que ces contextes impliquent" moreLink="" />
<Card4 title="Angular" customer="" description="J'ai beaucoup travaillé avec Angular ces dernières années, j'en maîtrises les principes et les composantes et ai développé et déployé plusieurs applications simples développées ou basées sur Angular" moreLink="" />
<Card4 title="Wordpress" customer="" description="J'ai une longue expérience de Wordpress, avec lequel j'ai créé des dizaines de sites, y compris des sites applicatifs. Je comprends combien il peut être utile pour gagner du temps, et quand il vaut mieux lui privilégier d'autres langages" moreLink="" />
<Card4 title="Apache" customer="" description="Je comprends bien le rôle du serveur Apache (ou ngnix) dans le fonctionnement d'une application web, et interviens régulièrement sur la configuration via le fichier htaccess pour ajuster les comportements de routage" moreLink="" />
<Card4 title="Notifications push" customer="" description="Qu'elles soient en provenance des serveurs iOS ou Google ou au contraire à l'initiative d'une application locale tournant en arrière plan, à destination d'une application mobile ou d'un navigateur, gérées directement ou par l'intermédiaire d'un outil tiers, je connais bien le fonctionnement et le potentiel des notifications" moreLink="" />
<Card4 title="SSL" customer="" description="Je sais installer et configurer un certificat SSL et comprends la sécurisation des échanges que permet le protocole HTTPS" moreLink="" />
<Card4 title="photoshop" customer="" description="J'utilise Photoshop au quotidien pour retravailler les images que j'intègre dans mes projets, pour les retailler ou pour les ajuster à des besoins spécifiques de transparence ou de visibilité" moreLink="" />
<Card4 title="Appsheet" customer="" description="J'utilise différentes plateforme de LowCode, en particulier Appsheet, pour créer et déployer des outils métier. Je comprends bien jusqu'où ces plateformes permettent d'aller, comment elles permettent de gagner du temps, mais ce pour quoi elles sont inadaptées" moreLink="" />
<Card4 title="Apple Appstore" customer="" description="Je connais très bien le fonctionnement du store d'Apple, ainsi que les processus et les contraintes liées à la publication d'applications" moreLink="" />
<Card4 title="Google Play Store" customer="" description="De même, j'ai beaucoup utilisé la console developer du Play Store, et continue à suivre les évolutions permanentes liées à la mise en avant et la publication d'application sur le store" moreLink="" />
<Card4 title="Documentation technique (DAT)" customer="" description="Je connais le formalisme de ce genre de document si structurant, et sais assumer le rôle de responsable technique en comprenant et arbitrant entre les différentes options techniques et leurs conséquences" moreLink="" />
<Card4 title="Documentation API" customer="" description="Rediger une documentation API exige de bien en maîtriser le formalisme et de comprendre tous les sous-jacents techniques, et me permet de bien connaître toutes les routes API du projet" moreLink="" />
<Card4 title="Postman" customer="" description="J'utilise Postman pour requêter les APIs d'outils tiers ou d'outils en cours de développement, ce qui me permet notamment de connaître les structures des données reçues ou mises en place" moreLink="" />
<Card4 title="Configuration email " customer="" description="Je sais mettre en place une messagerie mail en jouant sur les enregistrements MX, et optimiser la délivrabilité en configurant le DKIM, SPF, DMARC et autres attributs qui jouent sur la délivrabilité" moreLink="" />
<Card4 title="DNS" customer="" description="J'interviens régulièrement depuis le registrar pour modifier les DNS, et connais les délais et spécificités pouvant expliquer permettre de diagnostiquer des problèmes de DNS" moreLink="" />
<Card4 title="Environnements" customer="" description="Je travaille dans des environnements de développement complets intégrant généralement un espace de dev, de preprod et de prod, et j'ai l'habitude de m'appuyer sur des fichiers d'environnements liés à des commandes" moreLink="" />
<Card4 title="Proxy" customer="" description="Je sais prendre en compte la présence de proxy dans une infrastracture d'entreprise, que ce soit dans l'architecture des flux ou dans la configuration des requêtes dans le code" moreLink="" />
<Card4 title="VPN" customer="" description="J'ai mise en place une connexion VPN site à site pour sécuriser les échanges entre un serveur HDS et le site de notre client utilisateur" moreLink="" />
<Card4 title="Google Data Studio" customer="" description="Google Data Studio est un merveilleux outil pour afficher et faire vivre des dashboard de données dont les sources sont accessibles par API en évitant tout développement dédié" moreLink="" />
<Card4 title="SSH" customer="" description="J'utilise le SSH pour me connecter aux serveurs sur lesquels je travaille en m'appuyant sur la gestion des clés pour l'authentification" moreLink="" />
<Card4 title="FTP" customer="" description="Je me connecte en FTP aux serveurs sur lesquels je travaille via Filezilla, et je connais les spécificité des connexions FTP par rapport au HTTP, en particulier en ce qui concerne le transfert de fichiers" moreLink="" />
<Card4 title="Encryption" customer="" description="J'ai compris le rôle clé que jouait l'encryption dans la sécurité des données informatiques, je connais les différences de niveau d'encryption et les contraintes qu'elle peut impliquer" moreLink="" />
<Card4 title="Oauth" customer="" description="Je connais en détails le fonctionnement d'un serveur de jeton d'autorisation et les différents jetons composant une authentification et une consommation de données sécurisée" moreLink="" />
<Card4 title="LDAP" customer="" description="J'ai régulièrement eu à interagir avec des annuaires d'entreprises, et connais l'importance de travailler avec des groupes Active Directory ou autre pour éviter les difficultés liées à la gestion des accréditations" moreLink="" />
<Card4 title="SSO" customer="" description="Que ce soit pour fluidifier l'expérience des utilisateurs, ou du point de vue plus technique de sa mise en place, je connais bien ce sujet du SSO et son importance" moreLink="" />
<Card4 title="SAML" customer="" description="L'utilisation de jetons SAML, utilisés dans certains contextes bancaires dans lesquels j'ai travaillé, m'a permis de me familiariser avec ce sujet" moreLink="" />
<Card4 title="Analyse de riques" customer="" description="Dans le cadre de projets dans le secteur bancaire, j'ai eu l'occasion de rédiger des dossiers d'analyse de risque, à la croisée du métier, de la RSSI et de l'informatique" moreLink="" />
<Card4 title="EDI" customer="" description="J'ai connu différents types d'EDI selon les projets et les secteurs dans lesquels j'ai travaillé, souvent à l'aide de fichier à plat, dont les formats variaient (txt, json, xml…)" moreLink="" />
<Card4 title="BLE" customer="" description="J'ai travaillé sur de nombreux projets impliquant le BLE, et j'en connais les modes de fonctionnement et contraintes " moreLink="" />
<Card4 title="Wifi " customer="" description="J'ai aussi travaillé sur plusieurs projets impliquant des échanges via WIFI et les différents types de connexion possibles" moreLink="" />
<Card4 title="PWA" customer="" description="J'ai beaucoup creusé ce sujet des PWA pour les différents projets pour lesquels nous en avons développées, et comprends bien les promesses et les limites que permet cette technologie" moreLink="" />
<Card4 title="Local storage" customer="" description="Avant même la mise en place technique, l'utilisation du local storage est un élément clé de compréhension, indispensable pour structurer le fonctionnement sous jacent à une fonctionnalité offline." moreLink="" />
<Card4 title="Offline" customer="" description="Les logiques de stockage puis de synchronisation sont un sujet à part entière, complètement osculté à l'utilisateur, mais complexe et impossibles à improviser." moreLink="" />
<Card4 title="Mailchimp" customer="" description="J'utilise régulièrement Mailchimp ou d'autres outils d'emailing (Sendinblue, Mailjet, Campaigns…) pour gérer les listes de diffusions et envoyer des campagnes d'emails" moreLink="" />
<Card4 title="Google analytics" customer="" description="J'utilise Google Analytics au quotidien pour suivre l'activité des sites sur lesquels je travaille. Je configure également sa mise en place, de façon basique ou en m'appuyant sur des events" moreLink="" />
<Card4 title="Mixpanel" customer="" description="Mixpanel apporte des spécificités pour le mobile, et permet d'avoir des informations utiles sur des applications iOS ou Android" moreLink="" />
<Card4 title="Google Cloud Platform" customer="" description="J'ai utilisé de nombreuses options parmi toutes celles disponible sur le cloud de Google, et teste régulièrement l'intérêt de GCP, y compris en termes de coût" moreLink="" />
<Card4 title="Hébergement" customer="" description="Que ce soit pour un serveur dédié, un serveur virtuel ou du mutualisé, j'utilise tous les hébergeurs sans réelle préférence (OVH, Gandi, Planethoster, Ikoula, Titan, Ionos…) et sais identifier les spécificités qui peuvent convenir à certains projets plus qu'à d'autres" moreLink="" />
<Card4 title="Splaschscreen" customer="" description="Parmi les nombreuses composantes d'une application mobile, le splashscreen joue un rôle important et répond à des normes officielles et officieuses que j'ai déjà maintes fois eu l'occasion de mettre en pratique" moreLink="" />
<Card4 title="vHost" customer="" description="J'ai pris l'habitude d'intégrer la prise en compte du vHost pour diagnostiquer des erreurs de requêtes qui n'aboutissent pas ou qui reviennent en erreur" moreLink="" />
<Card4 title="Composer" customer="" description="Le gestionnaire de package de Laravel, porte d'entrée à toutes les librairies qui font de ce framework une très bonne option pour un projet d'entreprise" moreLink="" />
<Card4 title="Laravel" customer="" description="Un framework PHP parmi d'autres que nous avons beaucoup utilisé, et dont j'ai pu assimiler les composantes, les avantages et les limites" moreLink="" />
<Card4 title="ORM" customer="" description="Outil de lien entre le code et la base de données, qui structure le projet et facilite la gestion des requêtes à la base de données" moreLink="" />
<Card4 title="Gatsby" customer="" description="J'ai utilisé ce framework pour React permettant de générer une application statique selon les principes du JAMStack dont l'ambition se révèle chaque jour plus ferme " moreLink="" />
<Card4 title="Cloud functiuns" customer="" description="J'ai également pu utilisé les cloud functions mises à disposition sur ces hébergements intégrés, et constaté la facilité qu'ils permettent, même s'ils sont restreints à certains types de développements" moreLink="" />
<Card4 title="Headless CMS" customer="" description="Dans la continuité de ces tendances lourdes du développement web, j'ai mis en place et utilisé Strapi pour gérer du contenu et le rendre disponible à n'importe quelle application cliente via API" moreLink="" />
<Card4 title="MQTT" customer="" description="Ce protocole nous a été particulièrement utile pour certains projets IOT et permet de s'inscrire à des flux de données en tant que client" moreLink="" />
<Card4 title="SEO" customer="" description="Je connais très bien les fondamentaux du SEO, et sais les implémenter de façon simple, ou approfondir l'effort de référencement si c'est nécessaire à l'aide de techniques plus pointues" moreLink="" />
<Card4 title="CDN" customer="" description="J'utilise les serveurs de cache pour optimiser les performances de sites dont l'affichage peut ainsi être optimisé, et sais comment les prendre en compte pour éviter qu'il n'entrent en conflit avec des mises à jour " moreLink="" />
<Card4 title="Registrar" customer="" description="Tout comme l'hébergement, j'utilise différents registrar pour commander et configurer des noms de domaine" moreLink="" />
      </Box>
      </Container>
      </Grid>
    </Grid>
  );
}
